main {
    min-height: 80vh;
  }
  
  h3 {
    padding: 1rem 0;
  }
  
  h1 {
    font-size: 1.8rem;
    padding: 1rem 0;
  }
  
  h2 {
    font-size: 1.4rem;
    padding: 0.5rem 0;
  }
  
  .rating span {
    margin: 0.1rem;
  }
  
  /* carousel */
  .carousel-item-next,
  .carousel-item-prev,
  .carousel-item.active {
    display: flex;
  }
  .carousel-caption {
    position: absolute;
    top: 0;
  }
  
  .carousel-caption h2 {
    color: #fff;
  }
  
  .carousel img {
    height: 300px;
    padding: 30px;
    margin: 40px;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  .carousel a {
    margin: 0 auto;
  }
  @media (max-width: 900px) {
    .carousel-caption h2 {
      font-size: 2.5vw;
    }
  }

  .carousel-indicators {
    margin: 15px;
    height: 5px;
    padding: 15px  ;
  }

  .visually-hidden{
    visibility: hidden;
  }

  .carousel-indicators > button[aria-current="false"]{
    opacity: 50%;
  }

  .carousel-indicators > button{
    width: 30px;
  }

  span.page-link{
    width: 35.39px;
  }

  